<template>
    <div class="c-feedback" :class="{ 'c-feedback--message-sent' : requestMessage }">
        <h2 class="c-feedback__title" v-html="questions[currentStep]"/>
        <form class="c-form c-form--feedback c-feedback__form" @submit.prevent="submitFeedback">
            <div class="c-form__steps">
                <div class="c-form__step" :class="{ 'c-form__step--current' : fieldGroupIndex === currentStep }" v-for="(fieldGroup, fieldGroupIndex) in formData" :key="fieldGroupIndex">
                    <p v-if="lastStep" class="c-form__text">Dann hinterlasse uns doch deine E-Mail-Adresse. Ist aber kein Muß.</p>
                    <div class="c-form__group" v-for="(field, fieldIndex) in fieldGroup.items" :key="fieldIndex">
                        <label :for="`form_${field.name}`" class="c-form__label u-sr"> {{ field.placeholder }} </label>
                        <form-field :data="field" v-on:form-input="feedbackInput"/>
                    </div>
                    <p v-if="lastStep" class="c-form__text c-form__text--disclaimer"><small>Um deine Frage zu beantworten, benötigen wir deine E-Mail-Adresse.</small></p>
                </div>
            </div>
            <div class="c-form__controls">
                <button
                        v-if="currentStep >= 1"
                        class="c-btn c-txt c-txt--upper c-form__btn c-form__btn--prev"
                        @click.prevent="decrementFeedbackStep"
                >
                    <inline-svg :src="require(`@/assets/icons/arrow/left.svg`)"/>
                    Zurück
                </button>
                <next-button
                        class="c-btn c-txt c-txt--upper c-form__btn"
                        :class="{ 'c-btn--disabled' : !validInput }"
                        :disabled="!validInput"
                        :step="currentStep"
                        :lastStep="lastStep"
                        v-on:submit-feedback="submitFeedback"
                />
            </div>
        </form>
        <h2 v-if="requestMessage" class="c-feedback__message" v-html="requestMessage"/>
    </div>
</template>

<script>
    import api from '@/api'
    import {mapGetters, mapActions} from 'vuex'
    import InlineSvg from 'vue-inline-svg'

    import nextButton from "@/components/partials/Feedback/nextButton";
    import formField from '@/components/partials/formField';

    export default {
        name: 'Feedback',
        props: {
            data: Object
        },

        mounted() {
            this.updateFeedbackStep(0)
        },

        data() {
            return {
                questionText: '',
                userMail: '',
                validInput: false,
                requestMessage: '',
                questions: [
                    'Deine <span>Frage</span> ans Redaktionsteam.',
                    'K&ouml;nnen wir dich dazu <span>anschreiben</span>?'
                ]
            }
        },

        methods: {
            ...mapActions({
                updateFeedbackStep: 'updateFeedbackStep',
                decrementFeedbackStep: 'decrementFeedbackStep'
            }),

            feedbackInput(val, name, required) {
                if (name === 'message') this.questionText = val
                if (name === 'email') this.userMail = val
                if (required) this.validInput = !!val
            },

            submitFeedback() {
                const bodyFormData = new FormData();
                bodyFormData.set('message', this.questionText)
                bodyFormData.set('email', this.userMail)

                api.submitFeedback(this.data.feedback_form.form_id, bodyFormData, cb => {
                        this.requestMessage = cb.message
                    }
                )
            }
        },

        computed: {
            ...mapGetters(['currentStep']),

            formData() {
                const formDataObj = this.data.feedback_form.form_data
                return Object.keys(formDataObj).map(key => formDataObj[key])
            },

            lastStep() {
                return Number(this.currentStep) === this.formData.length - 1
            }
        },

        components: {
            formField,
            nextButton,
            InlineSvg
        }
    }
</script>

<style lang="scss" src="@/styles/components/_feedback.scss"></style>
<style lang="scss" src="@/styles/components/_form.scss"></style>