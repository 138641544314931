<script>
    import { mapActions } from 'vuex'
    import InlineSvg from 'vue-inline-svg'

    export default {
        name: 'stepsButton',

        props: {
            step: Number,
            lastStep: Boolean
        },

        render(h) {
            const lastStep = this.lastStep;

            return h('button', {
                    class: lastStep ? 'c-form__btn--submit' : 'c-form__btn--next',
                    domProps: {
                        type: lastStep ? 'submit' : 'button',
                    },
                    on: {
                        click: (event) => {
                            if (!lastStep) event.preventDefault()
                            lastStep ? this.$emit('submit-form') : this.incrementFeedbackStep()
                        }
                    }
                }, [
                    lastStep ? 'Absenden' : 'Weiter',
                    h('inline-svg', {
                        props: {
                            src: require(`@/assets/icons/arrow/right.svg`)
                        }
                    })
                ]
            )
        },

        methods: {
            ...mapActions(['incrementFeedbackStep'])
        },

        components: {
            InlineSvg
        }
    }
</script>