<script>
    export default {
        name: 'formField',
        props: {
            data: Object
        },

        render(h) {
            const data = this.data;
            const inputType = data.type === 'textarea' ? data.type : 'input';

            return h(inputType, {
                    class: `c-form__input c-form__input--${data.type}`,
                    domProps: {
                        required: data.required,
                        maxLength: data.type === 'textarea' ? '250' : '254',
                        rows: data.type === 'textarea' ? '6' : null,
                        name: data.name,
                        placeholder: data.placeholder,
                        id: `form_${data.name}`,
                        type: data.type,
                        value: this.value
                    },
                    on: {
                        input: (event) => {
                            this.$emit('form-input', event.target.value, event.target.name, event.target.required)
                        }
                    }
                }
            )
        }
    }
</script>